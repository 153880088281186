<script setup lang="ts">

import { emitter } from '~/events'
import type TransactionMethod from '~/interfaces/transaction_methods';
import MiPixLogo from '~/assets/logo.json'

const selected = ref<any>()
const error = ref<string | null>(null)
const cashboxOpened = ref<boolean>(false)
const modalType = ref<'deposit' | 'withdraw'>('deposit')
const methods = ref<TransactionMethod>()

const pixcode = ref<string>('')
const qrcode = ref<string>('')
const showPayment = ref<boolean>(false)
const codeInput = ref<HTMLInputElement>()

const $http = useHttp()
const toast = useToast()
const appStore = useAppStore()
const authStore = useAuthStore()

const formData = reactive({
  amount: 0,
  promotion: '',
  methodId: ''
})

const emitterEvent = (event: any) => {
  if (typeof event == 'string' && event == 'open' || typeof event == 'object' && event.action == 'open'){
    if(typeof event == 'object' && event.action == 'open')
      modalType.value = event.type

    cashboxOpened.value = true
  } else if (typeof event == 'string' && event == 'close' || typeof event == 'object' && event.action == 'open'){
    document.querySelector('body')?.classList.remove('auth-page')
    cashboxOpened.value = false
  }
}

onMounted(() => emitter.on('cashbox-modal', emitterEvent))
onUnmounted(() => emitter.off('cashbox-modal', emitterEvent))

const defaultSelected = () => {
  if(!methods.value) return

  showPayment.value = false
  const methodGroups = methods.value?.groups?.filter(g => methods.value?.methods.some(m => m.groups.some(gr => gr.id == g.id)))
  selected.value = methods.value?.methods.find(m => m.groups.some(gr => methodGroups?.length && gr.id == methodGroups[0].id))
  selected.value.cashbox = selected.value?.cashboxCheckBox.split(',').map((c: string) => parseInt(c))
  if(methodGroups)
    selected.value.groupId = methodGroups[0].id
  
  formData.methodId = selected.value?.id
  formData.amount = selected.value?.limit['BRL'].min
  formData.promotion = ''
}

const closeModal = (): Promise<void> => emitter.emit('cashbox-modal', 'close')

watch(() => formData.amount, (val) => {
  showPayment.value = false
  if (val > selected.value?.limit['BRL'].max) {
    const maxAmount = $numberFormat(selected.value?.limit['BRL'].max, 2, ',', '.')
    
    if(modalType.value == 'withdraw')
      error.value = `Só é permitido o saque de até R$${maxAmount}`
    else
      error.value = `Só é permitido o depósito de até R$${maxAmount}`
    return;
  } else if (val < selected.value?.limit['BRL'].min) {
    const minAmount = $numberFormat(selected.value?.limit['BRL'].min, 2, ',', '.')

    if(modalType.value == 'withdraw')
      error.value = `O valor mínimo para saque é de R$${minAmount}`
    else
      error.value = `O valor mínimo para depósito é de R$${minAmount}`
    return;
  }

  error.value = null
})

watch(() => modalType.value, async (type) => {
  try {
    methods.value = await authStore.fetchTransactionMethods(type);
    defaultSelected()
  } catch (error) {
    console.log(error)
  }
})

watch(cashboxOpened, async (val) => {
  if(val && !selected.value)
    methods.value = await authStore.fetchTransactionMethods(modalType.value);

  defaultSelected()
})

const createPayment = async () => {
  if (error.value) {
    return
  }

  if(modalType.value == 'withdraw') {
    const maxAmount = selected.value?.limit['BRL'].max
    const minAmount = selected.value?.limit['BRL'].min
    
    if(formData.amount > maxAmount) {
      error.value = `Só é permitido o saque de até R$${$numberFormat(maxAmount, 2, ',', '.')}`
      return;
    } else if(formData.amount < minAmount) {
      error.value = `O valor mínimo para saque é de R$${$numberFormat(minAmount, 2, ',', '.')}`
      return;
    }
  }

  try {
    appStore.setLoading(true)
    if(modalType.value == 'deposit') {
      const { data } = await $http.post('/wallet/deposit', formData)

      pixcode.value = data.pix_copiacola
      qrcode.value = data.pix_qrcode_url
      showPayment.value = true
    } else if(modalType.value == 'withdraw') {
      await $http.post('/wallet/withdraw', formData)
      showPayment.value = true
    }
  } catch (err: any) {
    showPayment.value = false

    if(!err.response || err.response && err.response.status != 422) {
      toast.add({
        severity: 'error',
        summary: 'Erro!',
        detail: 'Não conseguimos processar sua solicitação no momento, tente novamente.',
        life: 5000
      })
    } else {
      const { data: { errors } } = err.response

      const errKeys = Object.keys(errors)
      error.value = errors[errKeys[0]][0]
    }
  } finally {
    appStore.setLoading(false)
  }
}

const selectText = () => {
  const inputElement = codeInput.value as HTMLInputElement;
  inputElement.select();
}

const { copy, copied, isSupported } = useClipboard({ source: pixcode, legacy: true, copiedDuring: 3000 })
</script>
<template>
  <Dialog v-model:visible="cashboxOpened" modal :show-header="false" :style="{ width: '50rem' }" content-class="!px-0 !pb-0 !pt-0 !bg-black !rounded-3xl rounded-b-3xl rounded-bl-3xl rounded-br-3xl" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <div class="w-full shadow-lg rounded-3xl sm:h-[40rem]">
      <div class="w-full full grid grid-cols-1 sm:grid-cols-2 sm:max-h-[40rem] overflow-hidden">
        <div class="flex flex-col gap-4 p-4">
          <div class="flex justify-between items-center relative">
            <h4 class="text-white/80">Checkout</h4>
            <div class="block sm:hidden">
              <button
                @click="closeModal()"
                class="text-gray-400 rounded-3xl text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-white"
              >
                <Icon name="mipix:close" class="w-7 h-7"/>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
          </div>
          <img loading="lazy" src="~/assets/images/checkout.png" class="hover:opacity-80 cursor-pointer hidden sm:block">
          <div class="header-buttons">
            <MiPixButton class="header-btn" :class="{'inactive': modalType != 'deposit'}" @click="() => modalType = 'deposit'">Depositar</MiPixButton>
            <MiPixButton class="header-btn" :class="{'inactive': modalType != 'withdraw'}" @click="() => modalType = 'withdraw'">Sacar</MiPixButton>
          </div>
          <div class="w-full flex flex-col justify-between gap-4 relative before-gradient mt-2 sm:mt-3">
            <div class="w-full flex flex-col">
              <div class="w-full flex flex-col gap-2 mt-6">
                <label class="font-semibold text-white/80">Valor para <span v-if="modalType == 'deposit'">Depósito</span><span v-else-if="modalType == 'withdraw'">Saque</span></label>
                <div class="w-full grid grid-cols-4 gap-3 mb-4 mt-4">
                  <MiPixButton v-for="cashbox in selected?.cashbox" class="btn-amount" :class="{'inactive': formData.amount != cashbox}" @click="() => (formData.amount = cashbox)">{{ cashbox }}</MiPixButton>
                </div>
                <div class="w-full relative sm:pb-14 sm:h-32">
                  <InputGroup class="pb-2">
                    <InputGroupAddon class="!bg-secondary-950/70 border-none">
                      <span class="font-light text-lg">R$</span>
                    </InputGroupAddon>
                    <InputText type="text" v-model="formData.amount" class="w-full xl:text-base !bg-secondary-950/70 border-none focus:ring-0 rounded-r-xl" placeholder="Valor do saque" />
                  </InputGroup>
                  <small class="text-red-400 text-xs" :class="{ 'invisible': !error }">{{ error ?? '-' }}</small>
                </div>
                <div class="w-full hidden sm:block relative after-gradient"></div>
                <div class="w-full hidden sm:flex justify-center items-center mt-3">
                  <div class="w-32">
                    <Vue3Lottie :animationData="MiPixLogo" />
                  </div>
                  <div class="w-full">
                    <img loading="lazy" src="~/assets/images/security-banner.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- RIGHT CONTENT -->
        <div class="w-full h-full sm:h-[40rem] flex flex-col sm:bg-secondary-950/70 p-4 rounded-3xl -mt-6 sm:-mt-0">
          <div class="w-full h-full flex flex-col justify-between gap-4">
            <div class="w-full flex flex-col gap-4">
              <div class="flex justify-between items-center relative after-gradient pb-2">
                <h4 class="text-white/80 text-xl sm:text-base" v-if="modalType == 'deposit'">Depósito</h4>
                <h4 class="text-white/80" v-if="modalType == 'withdraw'">Saque</h4>
                <div class="hidden sm:block">
                  <button
                    @click="closeModal()"
                    class="text-gray-400 rounded-3xl text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-white"
                  >
                    <Icon name="mipix:close" class="w-7 h-7"/>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
              </div>
              <div class="w-full flex flex-col" v-if="selected && !showPayment">
                <div class="flex mb-4">
                  <div class="flex flex-col items-start justify-start">
                    <div class="text-xs font-normal">Min:</div>
                    <div class="text-xl font-normal text-white">R${{ $numberFormat(selected?.limit['BRL'].min, 2, ',', '.') }}</div>
                  </div>
                  <div class="flex text-xl font-normal text-gray-400/50 justify-center items-center mx-6">/</div>
                  <div class="flex flex-col items-start justify-start">
                    <div class="text-xs font-normal">Max:</div>
                    <div class="text-xl font-normal text-white">R${{ $numberFormat(selected?.limit['BRL'].max, 2, ',', '.') }}</div>
                  </div>
                </div>
                <ul class="comments max-h-72 overflow-auto" v-if="selected?.comments">
                  <li v-for="comment in selected?.comments?.filter((c: any) => !c.text.includes('${currency}'))">
                    <div class="comment-item">
                      <div class="p-3 relative">
                        <div class="overflow-hidden">
                          <p>{{ comment.text }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="selected && showPayment">
              <div v-if="modalType == 'deposit'" class="w-full">
                <h3 class="text-center text-white">Passos para pagar:</h3>
                <div class="flex flex-col gap-4 mt-4 text-white">
                  <span>Inicie o aplicativo de pagamento em seu celular.</span>
                  <span>Para realizar o pagamento, você pode escanear o código QR ou copiar a mensagem e abri-la por meio do aplicativo.</span>
                  <span>Complete o processo de confirmação de pagamento em seu celular.</span>

                  <div class="flex flex-col gap-2 mt-4 text-gray-400/90">
                    <span>Copie e cole este endereço</span>
                    <input ref="codeInput" @click="selectText" type="text" readonly class="rounded-lg border block flex-1 w-full text-sm p-1.5 bg-transparent border-gray-600 placeholder-gray-400 text-white ring-0 focus:ring-gray-600 focus:ring-0 focus:border-gray-600" :value="pixcode" />
                    <div class="mt-4 text-center" v-if="isSupported"><b>ou escaneie o código</b></div>
                    <div class="mt-4 text-center" v-else><b>escaneie o código</b></div>
                    <img alt="qr-code" loading="lazy" class="qr-container__qr-code-image max-w-[135px] mx-auto rounded-md" :src="qrcode">
                  </div>
                </div>
              </div>
              <div v-else-if="modalType == 'withdraw'" class="w-full flex flex-col items-center justify-center mt-8 sm:mt-0">
                <div class="w-20 mb-10">
                  <img loading="lazy" src="~/assets/images/mipix.svg">
                </div>
                <h4 class="text-lg font-bold text-green-500 text-center">Ação concluída com sucesso</h4>
                <div class="flex flex-col gap-4 mt-4 text-white">
                  <span class="text-center">Solicitação de saque realizada com sucesso, em até 1 hora o valor ja
            estará na sua conta!</span>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-col gap-2">
              <div class="w-full flex flex-col" v-if="isSupported && modalType == 'deposit' && selected && showPayment">
                <MiPixButton class="w-full !rounded-xl" v-if="!copied" @click="copy(pixcode)">COPIAR CÓDIGO PIX</MiPixButton>
                <h4 v-else-if="copied" class="text-primary-300 text-center">Código copiado com Sucesso!!</h4>
              </div>
              <MiPixButton v-else-if="modalType == 'deposit' && selected && !showPayment" class="w-full !rounded-xl" @click="createPayment">Gerar Pix</MiPixButton>
              <MiPixButton v-else-if="modalType == 'withdraw' && selected && !showPayment" class="w-full !rounded-xl" @click="createPayment">SOLICITAR SAQUE</MiPixButton>
            </div>
            <div class="w-full block sm:hidden relative after-gradient mt-4"></div>
            <div class="w-full flex sm:hidden justify-center items-center mt-2 pb-4">
              <div class="w-32">
                <Vue3Lottie :animationData="MiPixLogo" />
              </div>
              <div class="w-full">
                <img loading="lazy" src="~/assets/images/security-banner.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<style scoped>
  .header-buttons {
    @apply w-full h-12 rounded-xl grid grid-cols-2 gap-2 mt-0 sm:mt-4;
  }
  .header-buttons .header-btn {
    @apply w-full h-full flex justify-center items-center rounded-xl font-light;
  }
  .header-buttons .header-btn.inactive, .btn-amount.inactive {
    @apply bg-secondary-950 text-white;
  }

  .btn-amount {
    @apply flex justify-center items-center py-2 text-xs rounded-xl;
  }

  .comments:last-of-type {
    padding: 0 1rem;
    margin: 0 -1rem 1rem;
  }

  .comments li {
    @apply relative pb-[1px] before:bg-secondary-950 before:content-[''] before:h-[1px] before:-ml-4 before:absolute before:top-0 before:w-[calc(100%+2rem)] after:bg-secondary-950 after:content-[''] after:h-[1px] after:-ml-4 after:absolute after:w-[calc(100%+2rem)];
  }

  .comments .comment-item {
    @apply relative before:bg-primary-300 before:h-[calc(100%-4px)] before:top-[2px] before:-left-1 before:absolute before:w-[2px];
  }
</style>