<template>
  <button class="btn-base" :class="variantClass">
    <div v-if="label">{{ label }}</div>
    <slot v-else />
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{ label?: string, outline?: boolean, variant?: 'primary' | 'danger' }>()

const variantClass = computed(() => {
  if (props.outline && props.variant) {
    return `btn-outline btn-outline-${props.variant}`
  } else if (props.outline) {
    return `btn-outline btn-outline-primary`
  } else if (props.variant) {
    return `btn-${props.variant}`
  }

  return 'btn-primary'
})
</script>

<style>
.btn-base {
  @apply p-2.5 text-center font-medium rounded-md;
}

.btn-primary {
  @apply focus:outline-none text-secondary-950 bg-primary-500 hover:bg-primary-400 focus:ring-1 focus:ring-green-300 shadow-primary-500 border-none
}

.btn-danger {
  @apply hover:from-red-600/80 hover:to-red-500/80 hover:text-white/70;
  @apply from-red-600 to-red-500 bg-gradient-to-br border-none;
}

.btn-outline {
  @apply bg-transparent to-transparent from-transparent;
  @apply hover:to-transparent hover:from-transparent;
  @apply border-double border;
}

.btn-outline-primary {
  @apply hover:border-primary-500/70 hover:text-primary-300;
  @apply border-primary-600/70 text-primary-300/80;
}

.btn-outline-danger {
  @apply hover:border-red-500/70 hover:text-red-300;
  @apply border-red-600/70 text-red-300/90;
}
</style>