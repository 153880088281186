import { defineStore } from 'pinia'
import { useAppStore } from './app'
import type Transaction from '~/interfaces/transaction'
import type User from '~/interfaces/user'
import type TransactionMethod from '~/interfaces/transaction_methods'

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      authType: <'auth-email' | 'auth-signup' | 'forgot-password' | null>null,
      oldAuthType: <'auth-email' | 'auth-signup' | 'forgot-password' | null>(
        null
      ),
      oauthData: <any | null>null,
      visibleData: <boolean>false,
      balanceLoading: <boolean>false,
      transactions: <Transaction[]>[],
      user: <User>{},
      sportsHash: <string>'-'
    }
  },
  getters: {
    isLoggedIn: (state) =>
      state.user &&
      state.user.id !== undefined &&
      (state.user.id !== null || state.user.id != '')
  },
  actions: {
    async login(
      type: 'email' | 'oauth2',
      email: string = '',
      password: string = ''
    ): Promise<void> {
      const $http = useHttp()
      const route = useRoute()

      if (type === 'email') {
        const { data: auth } = await $http.post('/authentication/login', {
          email: email,
          password: password
        })

        this.setUser(auth.user)
      } else if (type === 'oauth2') {
        const { data: auth } = await $http.get(
          '/authentication/oauth2/callback/google',
          {
            params: route.query
          }
        )

        this.setUser(auth.user)
      }

      await this.fetchWallet()
    },

    async update(data: any): Promise<void> {
      const $http = useHttp()

      await $http.put('/account', data)
      await this.fetchUser()
    },

    async signUp(data: any): Promise<void> {
      const $http = useHttp()
      const { data: auth } = await $http.post('/authentication/register', data)

      this.setUser(auth.user)
      await this.fetchWallet()
    },

    async forgotPassword(data: Partial<User>): Promise<void> {
      const $http = useHttp()
      await $http.post('/authentication/forgot-password', data)
    },

    async resetPassword(data: any): Promise<void> {
      const $http = useHttp()
      await $http.post('/authentication/reset-password', data)
    },

    async logout(): Promise<void> {
      try {
        const $http = useHttp()
        await $http.post('/authentication/logout')
      } catch (_) {
      } finally {
        this.setUser(undefined)
      }
    },

    setDataVisible(visible: boolean) {
      this.visibleData = visible
      localStorage.visibleData = this.visibleData
    },

    toogleDataVisible() {
      this.setDataVisible(!this.visibleData)
    },

    setUser(user: User | undefined) {
      if (user) this.user = user
      else {
        this.user = <User>{}
        this.sportsHash = '-'
      }
    },

    async fetchUser(): Promise<void> {
      const $http = useHttp()
      const {
        data: { data: auth }
      } = await $http.get('/account')
      this.setUser(auth)
    },

    async fetchTransactionMethods(
      type: 'deposit' | 'withdraw'
    ): Promise<TransactionMethod> {
      const $http = useHttp()
      const { data } = await $http.get(`/wallet/transaction-methods/${type}`)

      return data
    },

    setUserBalance(balance: typeof this.user.wallet | any) {
      this.user.wallet = balance
    },

    async fetchWallet(): Promise<void> {
      const appStore = useAppStore()
      appStore.enableLoading(false)
      this.balanceLoading = true
      try {
        const $http = useHttp()
        const { data: balance } = await $http.get('/wallet/balance')
        this.setUserBalance(balance)
      } catch (_) {
      } finally {
        this.balanceLoading = false
        appStore.enableLoading(true)
      }
    },

    async fetchTransactions(
      type: Transaction['src'],
      period: string = 'today'
    ): Promise<void> {
      try {
        const $http = useHttp()
        const { data: transactions } = await $http.get('/wallet/transactions', {
          data: { type: type, period: period }
        })
        this.transactions = transactions
      } catch (_) {}
    },

    async createSportsHash(): Promise<void> {
      try {
        const $http = useHttp()
        const {
          data: { hash }
        } = await $http.get('/GetHash')
        this.sportsHash = hash
      } catch (_) {}
    },

    setOauth(data: any | undefined) {
      if (data) this.oauthData = data
      else this.oauthData = null
    },

    changeType(type: typeof this.authType) {
      this.oldAuthType = this.authType
      this.authType = type
    }
  },
  persist: [
    {
      key: 'mipix-auth',
      storage: persistedState.cookiesWithOptions({
        maxAge: 60 * 60 * 24 * 365 * 10,
        expires: new Date(Date.now() + 60 * 60 * 24 * 365 * 10)
      }),
      paths: ['user']
    },
    {
      key: 'mipix-data',
      storage: persistedState.cookies,
      paths: ['visibleData']
    }
  ]
})
