import type { InputTextPassThroughOptions } from 'primevue/inputtext'

export default {
  root: ({ props, context, parent }) => {
    var _a
    return {
      class: [
        // Font
        'leading-[normal] w-full',
        // Flex
        { 'flex-1 w-full': parent.instance.$name == 'InputGroup' },
        // Spacing
        'm-0',
        {
          'px-4 py-4': props.size == 'large',
          'px-2 py-2': props.size == 'small',
          'p-3': props.size == null
        },
        // Shape
        { 'rounded-md': parent.instance.$name !== 'InputGroup' },
        {
          'first:rounded-l-md rounded-none last:rounded-r-md':
            parent.instance.$name == 'InputGroup'
        },
        {
          'border-0 border-y border-l last:border-r':
            parent.instance.$name == 'InputGroup'
        },
        // Colors
        'text-surface-800 dark:text-white/80',
        'placeholder:text-surface-400 dark:placeholder:text-surface-500',
        'bg-surface-0 dark:bg-surface-900',
        'border',
        {
          'border-[hsla(0,0%,100%,.19)] dark:border-surface-600': !props.invalid
        },
        // Invalid State
        'invalid:focus:ring-red-200',
        'invalid:hover:border-red-500',
        { 'border-red-500 dark:border-red-400': props.invalid },
        // States
        {
          'hover:border-primary-300': !context.disabled && !props.invalid,
          'focus:outline-none focus:outline-offset-0 focus:ring-0 focus:ring-primary-300/50 focus:border-primary-300 dark:focus:ring-primary-400/50 focus:z-10':
            !context.disabled,
          'opacity-60 select-none pointer-events-none cursor-default':
            context.disabled
        },
        // Filled State *for FloatLabel
        {
          filled:
            ((_a = parent.instance) == null ? void 0 : _a.$name) ==
              'FloatLabel' && context.filled
        },
        // Misc
        'appearance-none',
        'transition-colors duration-200'
      ]
    }
  }
} satisfies InputTextPassThroughOptions
