import { defineStore } from 'pinia'
import type Config from '~/interfaces/config'
import type Game from '~/interfaces/game'
import type Lobby from '~/interfaces/lobby'

export const useAppStore = defineStore('app', {
  state: () => {
    return {
      configs: <Partial<Config>>{
        maintenance: false,
        appSource: undefined,
        appVersion: undefined,
        appStandalone: false,
        language: import.meta.env.VITE_DEFAULT_LOCALE || 'pt'
      },
      isLoading: <boolean>true,
      globalLoading: <boolean>true,
      lobbyItems: <Lobby[]>[],
      gameList: <Game[]>[],
      breakpoints: {
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1536px'
      }
    }
  },
  getters: {
    appName: (): string => import.meta.env.VITE_APP_NAME,
    appVersion: (): string => process.env.APP_VERSION as string
  },
  actions: {
    setLobby(lobbies: Lobby[]) {
      this.lobbyItems = lobbies.filter(
        (lobby) => lobby.items.total > 0 || lobby.providers.length > 0
      )
    },

    setGameList(games: Game[]) {
      this.gameList = games
    },

    setAppSource(data: any): void {
      if (data.app_source) this.configs.appSource = data.app_source
      if (data.standalone)
        this.configs.appStandalone = data.standalone == 'true'
      if (data.v) this.configs.appVersion = data.v
    },

    enableLoading(enabled: boolean) {
      this.globalLoading = enabled
    },

    setConfigs(setting: Config) {
      this.configs = setting
      if (import.meta.browser && setting.language && !localStorage.lang)
        this.setLang(setting.language)
    },

    setLang(lang: typeof this.configs.language) {
      this.configs.language = lang
      if (import.meta.browser) localStorage.lang = this.configs.language
    },

    setLoading(loading: boolean) {
      if (import.meta.browser) {
        if (loading && this.globalLoading)
          document.querySelector('body')?.classList.add('overflow-hidden')
        else document.querySelector('body')?.classList.remove('overflow-hidden')

        if (loading && !this.globalLoading) return

        this.isLoading = loading
      }
    },

    async fetchConfigs(): Promise<void> {
      const $http = useHttp()

      const { data: configs } = await $http.get('/app', {
        params: {
          app_source: this.configs.appSource,
          v: this.configs.appVersion
        }
      })
      const {
        ss: maintenance,
        ll: language
      }: { ss: boolean; ll: 'pt' | 'en' } = configs
      configs.maintenance = maintenance
      configs.language = language

      delete configs.ll
      delete configs.ss
      this.setConfigs(configs)
    }
  },
  persist: [
    {
      storage: persistedState.localStorage,
      paths: ['configs']
    }
  ]
})
