import xior, { XiorError } from 'xior'
import type {
  XiorInterceptorRequestConfig,
  XiorResponse,
  XiorResponseInterceptorConfig
} from 'xior'
import { parseCookies } from 'h3'

const CSRF_COOKIE = 'XSRF-TOKEN'
const CSRF_HEADER = 'X-XSRF-TOKEN'

const onRequest = async (
  request: XiorInterceptorRequestConfig<any>
): Promise<XiorInterceptorRequestConfig> => {
  const { method, url } = request
  const appStore = useAppStore()
  const { baseURL: frontendUrl } = useRuntimeConfig().public

  const event = typeof useEvent === 'function' ? useEvent() : null
  let token = event
    ? parseCookies(event)[CSRF_COOKIE]
    : useCookie(CSRF_COOKIE).value

  if (
    import.meta.client &&
    ['post', 'delete', 'put', 'patch'].includes(
      request.method.toLowerCase() ?? ''
    )
  ) {
    token = await initCsrf()
  }

  // Set Headers Here
  let headers: typeof request.headers = {
    accept: 'application/json',
    ...request.headers,
    ...(token && { [CSRF_HEADER]: token })
  }

  if (import.meta.server) {
    const getIp = useRequestHeaders(['x-forwarded-for'])['x-forwarded-for']

    const cookieString = event
      ? event.headers.get('cookie')
      : useRequestHeaders(['cookie']).cookie

    headers = {
      ...headers,
      ...(cookieString && { cookie: cookieString }),
      referer: frontendUrl
    }

    if (getIp) {
      const [ip] = getIp.split(', ')
      headers['X-Forwarded-For'] = ip
    }
  }

  if (appStore.globalLoading) appStore.setLoading(true)

  if (import.meta.env.DEV)
    console.log(`🚀 [API] ${method?.toUpperCase()} ${url} | Request`)

  if (method === 'get') {
    request.timeout = 15000
  }

  request.headers = headers
  return request
}

const onResponse = async (response: {
  data: any
  config: XiorInterceptorRequestConfig<any>
  request: XiorInterceptorRequestConfig<any>
  response: Response
}): Promise<XiorResponseInterceptorConfig<any>> => {
  const { method, url } = response.config
  const { status } = response.response

  const appStore = useAppStore()
  appStore.setLoading(false)

  if (import.meta.env.DEV) {
    console.log('🚀 [API] %s %s [%s] | Response', method, url, status)
  }

  const processedResponse = {
    data: response.data,
    config: response.config,
    request: response.request,
    response: response.response,
    status: response.response.status,
    statusText: response.response.statusText,
    headers: response.response.headers
  }

  return processedResponse
}

const onErrorResponse = async (
  error: XiorError | Error
): Promise<XiorError> => {
  const appStore = useAppStore()
  const authStore = useAuthStore()

  appStore.setLoading(false)

  if (error instanceof XiorError) {
    const { method, url } = error.config as XiorInterceptorRequestConfig
    const { status, statusText } = (error.response as XiorResponse) ?? {}

    if (import.meta.env.DEV)
      console.log(`🚨 [API] ${method?.toUpperCase()} ${url} | Error ${status}`)

    if ([500].includes(status)) {
      console.error('[Server Error]', statusText)
    } else if ([401].includes(status)) {
      // "Login required"
      authStore.setUser(undefined)
    } else if ([419].includes(status)) {
      console.error('[Session Expired]')
    }
  } else {
    if (import.meta.env.DEV) console.log(`🚨 [API] | Error ${error.message}`)
  }

  return Promise.reject(error)
}

async function initCsrf() {
  const existingToken = useCookie(CSRF_COOKIE).value
  const { baseURL: frontendUrl } = useRuntimeConfig().public

  if (existingToken) return existingToken

  await xior.get('/csrf', {
    baseURL: import.meta.env.VITE_APP_BACKEND,
    credentials: 'include',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      referer: frontendUrl
    }
  })

  return useCookie(CSRF_COOKIE).value
}

export default defineNuxtPlugin((nuxtApp) => {
  const instance = xior.create({
    withCredentials: true,
    credentials: 'include',
    baseURL: import.meta.env.VITE_APP_BACKEND
  })

  instance.defaults.headers.accept = 'application/json'
  instance.defaults.headers['Content-Type'] = 'application/json'

  instance.interceptors.request.use(onRequest, onErrorResponse)
  instance.interceptors.response.use(onResponse, onErrorResponse)

  nuxtApp.provide('http', instance)
})
