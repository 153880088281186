export default defineAppConfig({
  nuxtIcon: {
    size: '20',
    aliases: {
      'mipix:show-data': 'ph:eye',
      'mipix:hide-data': 'ph:eye-slash',
      'mipix:back': 'ion:ios-arrow-left',
      'mipix:close': 'ion:close',
      'mipix:fullscreen-in': 'ri:fullscreen-exit-fill',
      'mipix:fullscreen-out': 'ri:fullscreen-fill'
    }
  }
})
