export const appBuildAssetsDir = "/_static/"

export const appHead = {"link":[{"rel":"icon","type":"image/svg+xml","href":"/favicon.svg"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"keywords","content":"MiPix, cassino online, jogos de cassino, caça-níqueis, roleta, blackjack, poker"},{"name":"facebook-domain-verification","content":"hay3c7flmkb64xz5bydkqcoluvi03c"},{"property":"og:type","content":"website"}],"style":[],"script":[{"type":"text/javascript","src":"https://725d0078-96d7-4957-9ff1-0dfb6980fc97.snippet.anjouangaming.org/anj-seal.js"},{"type":"text/javascript","children":"\n            !function(f,b,e,v,n,t,s)\n            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n            n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n            n.queue=[];t=b.createElement(e);t.async=!0;\n            t.src=v;s=b.getElementsByTagName(e)[0];\n            s.parentNode.insertBefore(t,s)}(window, document,'script',\n            'https://connect.facebook.net/en_US/fbevents.js');\n            fbq('init', '1653564538734449');\n            fbq('track', 'PageView');\n          "},{"type":"text/javascript","src":"undefined/js/Partner/IntegrationLoader.js"}],"noscript":[],"htmlAttrs":{}}

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000