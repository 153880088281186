export const $formatPhone = (phone: string) =>
  phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')

export const $numberFormat = (
  number: any,
  decPlaces: number = 2,
  decSep: string = '.',
  thouSep: string = ','
) => {
  var sign = number < 0 ? '-' : ''
  var i: any = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  )
  var j: number = (j = i.length) > 3 ? j % 3 : 0

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : '') +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : '')
  )
}
