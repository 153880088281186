import type { DialogPassThroughOptions } from 'primevue/dialog'

export default {
  root: ({ state }) => ({
    class: [
      // Shape
      'rounded-lg',
      'shadow-lg',
      'border-0',
      // Size
      'max-h-[97dvh]',
      'sm:max-h-[90vh]',
      'h-screen',
      'sm:h-auto',
      'w-[50vw]',
      'm-0',
      // Transitions
      'transform',
      'scale-100',
      // Maximized State
      {
        'transition-none': state.maximized,
        'transform-none': state.maximized,
        '!w-screen': state.maximized,
        '!h-screen': state.maximized,
        '!max-h-full': state.maximized,
        '!top-0': state.maximized,
        '!left-0': state.maximized
      }
    ]
  }),
  header: {
    class: [
      'flex items-center justify-between',
      'shrink-0',
      'p-3',
      'border-t-0',
      'rounded-tl-lg',
      'rounded-tr-lg',
      'bg-secondary-940 dark:bg-secondary-800',
      'text-white'
    ]
  },
  title: {
    class: ['font-bold text-lg']
  },
  icons: {
    class: ['flex items-center']
  },
  closeButton: {
    class: [
      'relative',
      'flex items-center justify-center',
      'mr-2',
      'last:mr-0',
      'w-7 h-7',
      'p-2',
      'border-0',
      'rounded-full',
      'text-surface-500',
      'bg-transparent',
      'transition duration-200 ease-in-out',
      'hover:text-surface-700 dark:hover:text-white/80',
      'hover:bg-surface-100 dark:hover:bg-surface-800/80',
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-inset',
      'focus:ring-transparent',
      'overflow-hidden'
    ]
  },
  maximizableButton: {
    class: [
      'relative',
      'flex items-center justify-center',
      'mr-2',
      'last:mr-0',
      'w-8 h-8',
      'border-0',
      'rounded-full',
      'text-surface-500',
      'bg-transparent',
      'transition duration-200 ease-in-out',
      'hover:text-surface-700 dark:hover:text-white/80',
      'hover:bg-surface-100 dark:hover:bg-surface-800/80',
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-inset',
      'focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
      'overflow-hidden'
    ]
  },
  closeButtonIcon: {
    class: ['inline-block', 'w-4', 'h-4']
  },
  maximizableIcon: {
    class: ['inline-block', 'w-4', 'h-4']
  },
  content: ({ state, instance }) => ({
    class: [
      // Spacing
      'px-6',
      'pb-8',
      'pt-2',
      // Shape
      {
        grow: state.maximized,
        'rounded-bl-lg': !instance.$slots.footer,
        'rounded-br-lg': !instance.$slots.footer
      },
      // Colors
      'bg-secondary-950 dark:bg-surface-800',
      'text-white dark:text-surface-0/80',
      // Misc
      'overflow-y-auto'
    ]
  }),
  footer: {
    class: [
      'flex items-center justify-end',
      'shrink-0',
      'text-right',
      'gap-2',
      'px-6',
      'pb-6',
      'border-t-0',
      'rounded-b-lg',
      'bg-surface-0 dark:bg-surface-800',
      'text-surface-700 dark:text-surface-0/80'
    ]
  },
  mask: ({ props }) => ({
    class: [
      // Transitions
      'transition-all',
      'duration-300',
      { 'p-2': props.position == 'center' },
      // Background and Effects
      {
        'has-[.mask-active]:bg-transparent bg-black/40': props.modal,
        'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': props.modal
      }
    ]
  }),
  transition: ({ props }) =>
    props.position === 'top'
      ? {
          enterFromClass:
            'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass:
            'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active'
        }
      : props.position === 'bottom'
      ? {
          enterFromClass: 'opacity-0 scale-75 translate-y-full mask-active',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass:
            'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0 mask-active'
        }
      : props.position === 'left' ||
        props.position === 'topleft' ||
        props.position === 'bottomleft'
      ? {
          enterFromClass:
            'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0 mask-active',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass:
            'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0 mask-active'
        }
      : props.position === 'right' ||
        props.position === 'topright' ||
        props.position === 'bottomright'
      ? {
          enterFromClass:
            'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass:
            'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active'
        }
      : {
          enterFromClass: 'opacity-0 scale-75 mask-active',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass: 'opacity-0 scale-75 mask-active'
        }
} satisfies DialogPassThroughOptions
