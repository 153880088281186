import { default as indexnhFtsEjACXMeta } from "/vercel/path0/src/pages/about/index.vue?macro=true";
import { default as indexIOxdNmy0rdMeta } from "/vercel/path0/src/pages/account/affiliate/index.vue?macro=true";
import { default as indexy2dPqYVViOMeta } from "/vercel/path0/src/pages/account/index.vue?macro=true";
import { default as index76InaS5f7PMeta } from "/vercel/path0/src/pages/account/overview/index.vue?macro=true";
import { default as indexeAcIFEArBWMeta } from "/vercel/path0/src/pages/account/profile/index.vue?macro=true";
import { default as indexE31G1MFzBaMeta } from "/vercel/path0/src/pages/account/refers/index.vue?macro=true";
import { default as accountWw8ScTLHWhMeta } from "/vercel/path0/src/pages/account.vue?macro=true";
import { default as indexVDgYl8PleTMeta } from "/vercel/path0/src/pages/app/index.vue?macro=true";
import { default as loginiDyrB7ID3PMeta } from "/vercel/path0/src/pages/auth/login.vue?macro=true";
import { default as _91token_93XNXxbhNqvtMeta } from "/vercel/path0/src/pages/auth/reset-password/[userId]/[token].vue?macro=true";
import { default as signupXGKCUOo1A2Meta } from "/vercel/path0/src/pages/auth/signup.vue?macro=true";
import { default as indexqQQb4QEI2bMeta } from "/vercel/path0/src/pages/betting-terms/index.vue?macro=true";
import { default as indexsXZ4v4IeMjMeta } from "/vercel/path0/src/pages/breve/index.vue?macro=true";
import { default as _91gameId_93RLr1raogpkMeta } from "/vercel/path0/src/pages/casino/[providerId]/[gameId].vue?macro=true";
import { default as indexKC7aORZswZMeta } from "/vercel/path0/src/pages/casino/all/index.vue?macro=true";
import { default as _91slug_93cCzDwoVypGMeta } from "/vercel/path0/src/pages/casino/lobby/[slug].vue?macro=true";
import { default as indexek6XNg1ZU9Meta } from "/vercel/path0/src/pages/casino/lobby/index.vue?macro=true";
import { default as _91provider_93tsmQ06r6e2Meta } from "/vercel/path0/src/pages/casino/provider/[provider].vue?macro=true";
import { default as indexGBzn6qkxPLMeta } from "/vercel/path0/src/pages/casino/provider/index.vue?macro=true";
import { default as erroroxxzu7WvKuMeta } from "/vercel/path0/src/pages/error.vue?macro=true";
import { default as indexv5Xn372QZrMeta } from "/vercel/path0/src/pages/index/index.vue?macro=true";
import { default as indexSh7dxcAX9hMeta } from "/vercel/path0/src/pages/kyc-policy/index.vue?macro=true";
import { default as maintenanceb7ALifEihtMeta } from "/vercel/path0/src/pages/maintenance.vue?macro=true";
import { default as offlineMYM1STZlVNMeta } from "/vercel/path0/src/pages/offline.vue?macro=true";
import { default as index1cMqI6Qin1Meta } from "/vercel/path0/src/pages/privacy/index.vue?macro=true";
import { default as indexF0h4Ytl9yMMeta } from "/vercel/path0/src/pages/promotions/[promotion]/index.vue?macro=true";
import { default as indexwqA11KCesOMeta } from "/vercel/path0/src/pages/promotions/index.vue?macro=true";
import { default as index4C9s2QYfW4Meta } from "/vercel/path0/src/pages/responsible-gaming/index.vue?macro=true";
import { default as indexiVaZX824K0Meta } from "/vercel/path0/src/pages/sports/index.vue?macro=true";
import { default as indexAgn7aGjc6lMeta } from "/vercel/path0/src/pages/telegram/index.vue?macro=true";
import { default as index3bkOnNTfX2Meta } from "/vercel/path0/src/pages/terms-and-conditions/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: indexnhFtsEjACXMeta || {},
    component: () => import("/vercel/path0/src/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: accountWw8ScTLHWhMeta?.name,
    path: "/account",
    meta: accountWw8ScTLHWhMeta || {},
    component: () => import("/vercel/path0/src/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-affiliate",
    path: "affiliate",
    meta: indexIOxdNmy0rdMeta || {},
    component: () => import("/vercel/path0/src/pages/account/affiliate/index.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "",
    meta: indexy2dPqYVViOMeta || {},
    component: () => import("/vercel/path0/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-overview",
    path: "overview",
    meta: index76InaS5f7PMeta || {},
    component: () => import("/vercel/path0/src/pages/account/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile",
    path: "profile",
    meta: indexeAcIFEArBWMeta || {},
    component: () => import("/vercel/path0/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-refers",
    path: "refers",
    meta: indexE31G1MFzBaMeta || {},
    component: () => import("/vercel/path0/src/pages/account/refers/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/vercel/path0/src/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/vercel/path0/src/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password-userId-token",
    path: "/auth/reset-password/:userId()/:token()",
    component: () => import("/vercel/path0/src/pages/auth/reset-password/[userId]/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    component: () => import("/vercel/path0/src/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "betting-terms",
    path: "/betting-terms",
    meta: indexqQQb4QEI2bMeta || {},
    component: () => import("/vercel/path0/src/pages/betting-terms/index.vue").then(m => m.default || m)
  },
  {
    name: "breve",
    path: "/breve",
    component: () => import("/vercel/path0/src/pages/breve/index.vue").then(m => m.default || m)
  },
  {
    name: "casino-providerId-gameId",
    path: "/casino/:providerId()/:gameId()",
    meta: _91gameId_93RLr1raogpkMeta || {},
    component: () => import("/vercel/path0/src/pages/casino/[providerId]/[gameId].vue").then(m => m.default || m)
  },
  {
    name: "casino-all",
    path: "/casino/all",
    component: () => import("/vercel/path0/src/pages/casino/all/index.vue").then(m => m.default || m)
  },
  {
    name: "casino-lobby-slug",
    path: "/casino/lobby/:slug()",
    meta: _91slug_93cCzDwoVypGMeta || {},
    component: () => import("/vercel/path0/src/pages/casino/lobby/[slug].vue").then(m => m.default || m)
  },
  {
    name: "casino-lobby",
    path: "/casino/lobby",
    component: () => import("/vercel/path0/src/pages/casino/lobby/index.vue").then(m => m.default || m)
  },
  {
    name: "casino-provider-provider",
    path: "/casino/provider/:provider()",
    meta: _91provider_93tsmQ06r6e2Meta || {},
    component: () => import("/vercel/path0/src/pages/casino/provider/[provider].vue").then(m => m.default || m)
  },
  {
    name: "casino-provider",
    path: "/casino/provider",
    component: () => import("/vercel/path0/src/pages/casino/provider/index.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    meta: erroroxxzu7WvKuMeta || {},
    component: () => import("/vercel/path0/src/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/src/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "kyc-policy",
    path: "/kyc-policy",
    meta: indexSh7dxcAX9hMeta || {},
    component: () => import("/vercel/path0/src/pages/kyc-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceb7ALifEihtMeta || {},
    component: () => import("/vercel/path0/src/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "offline",
    path: "/offline",
    meta: offlineMYM1STZlVNMeta || {},
    component: () => import("/vercel/path0/src/pages/offline.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: index1cMqI6Qin1Meta || {},
    component: () => import("/vercel/path0/src/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: "promotions-promotion",
    path: "/promotions/:promotion()",
    meta: indexF0h4Ytl9yMMeta || {},
    component: () => import("/vercel/path0/src/pages/promotions/[promotion]/index.vue").then(m => m.default || m)
  },
  {
    name: "promotions",
    path: "/promotions",
    component: () => import("/vercel/path0/src/pages/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: "responsible-gaming",
    path: "/responsible-gaming",
    meta: index4C9s2QYfW4Meta || {},
    component: () => import("/vercel/path0/src/pages/responsible-gaming/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: indexiVaZX824K0Meta || {},
    component: () => import("/vercel/path0/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "telegram",
    path: "/telegram",
    component: () => import("/vercel/path0/src/pages/telegram/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    meta: index3bkOnNTfX2Meta || {},
    component: () => import("/vercel/path0/src/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  }
]