import Echo from 'laravel-echo'
import 'pusher-js'

export default defineNuxtPlugin((nuxtApp) => {
  const $http = useHttp()

  const echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel: any, options: any) => {
      return {
        authorize: (socketId: any, callback: Function) => {
          $http
            .post('/broadcasting/auth', {
              socket_id: socketId,
              channel_name: channel.name
            })
            .then((response) => {
              callback(false, response.data)
            })
            .catch((error) => {
              callback(true, error.response)
            })
        }
      }
    }
  })

  nuxtApp.provide('echo', echo)
})
