export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) {
    const appStore = useAppStore()
    const queryParam = to.query
    appStore.setAppSource(queryParam)

    await appStore.fetchConfigs()

    if (appStore.configs.maintenance && to.name != 'maintenance')
      return await navigateTo('/maintenance')
  }
})
