<script setup lang="ts">
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import type { XiorError } from 'xior';

import MiPixLoading from '~/assets/loading.json'

const { setLocale } = useI18n()
const $http = useHttp()
const route = useRoute()
const appStore = useAppStore()
const authStore = useAuthStore()
const isLoading = ref<boolean>(false)
const userId = ref<string>()
const refCookie = useCookie('ref_code', { path: '/', maxAge: 3600 });
const affiliateCookie = useCookie('affiliate_code', { path: '/', maxAge: 3600 });
const mounted = useMounted()

const fetchData = async () => {
  const { data: { lobbies, games } } = await $http.get('/casino/data')
  
  appStore.setGameList(games.data)
  appStore.setLobby(lobbies)
}

onServerPrefetch(async () => await fetchData())

defineOgImage({
  component: 'root',
  url: '/images/mipix.png?v=' + import.meta.env.VITE_APP_VERSION,
  alt: import.meta.env.VITE_APP_NAME,
  width: 544,
  height: 544,
})

onBeforeMount(async () => {
  try {
    if (authStore.user.id) {
      await authStore.fetchUser()
    }
  } catch (err: any | XiorError) { }
  finally {
    if (localStorage.lang) {
      setLocale(localStorage.lang)
      appStore.setLang(localStorage.lang)
    }
  }
})

onMounted(async () => {
  authStore.changeType(null)

  if (route.query.ref) {
    refCookie.value = route.query.ref.toString();
    affiliateCookie.value = undefined
  } else if (route.query.btag) {
    affiliateCookie.value = route.query.btag.toString();
    refCookie.value = undefined
  }

  const { visitorId, version } = await FingerprintJS.load().then(fp => fp.get())
  console.log(visitorId, version)

  // Verifica atualizações do Service Worker
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      window.location.reload()
    })
  }

  const { $echo } = useNuxtApp();
  if (authStore.isLoggedIn) {
    userId.value = authStore.user.id
    $echo.private(`user.balance.${authStore.user.id}`)
      .listen('.user.balance', (msg: any) => authStore.setUserBalance(msg.balance));
  }
  appStore.enableLoading(true)
})

onUnmounted(() => {
  const { $echo } = useNuxtApp();
  $echo.leaveChannel(`private-user.balance.${authStore.user.id}`);
})

watch(
  () => appStore.isLoading,
  (loading: boolean) => {
    if (import.meta.server) isLoading.value = false

    isLoading.value = loading
  }
)

watch(() => route.fullPath, async (_) => {
  if(appStore.gameList.length === 0) await fetchData()
})

watch(() => appStore.configs.language, (lang) => setLocale(lang!))

watch(
  () => authStore.isLoggedIn,
  (isLoggedIn) => {
    const { $echo } = useNuxtApp();
    if (isLoggedIn) {
      userId.value = authStore.user.id
      $echo.private(`user.balance.${authStore.user.id}`)
        .listen('.user.balance', (msg: any) => authStore.setUserBalance(msg.balance));
    } else {
      $echo.leaveChannel(`private-user.balance.${userId.value}`);
      userId.value = undefined
    }
  }
)
</script>
<template>
  <div>
    <VitePwaManifest />
    <Toast />
    <div
      class="fixed w-full h-full top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 backdrop-blur-lg z-[9999] flex items-center justify-center"
      v-if="isLoading">
      <Vue3Lottie :animationData="MiPixLoading" :width="100" :loop="false" />
    </div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <CashboxModal />
    <!-- <CookieControl locale="en" /> -->
    <noscript v-if="mounted"><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1653564538734449&ev=PageView&noscript=1"/></noscript>
  </div>
</template>